import fetchData, { commentsFilter } from './services/api';
import fetchNavigationEvents from './services/fetch_navigation_events';
import { playerInfoRegexp, updateCommentsWithPlayerComment } from './helpers/comments_helper';

export const SCOREBOARD_INIT = 'scoreboard/INIT';
export const SCOREBOARD_REMOVE = 'scoreboard/REMOVE';
export const SCOREBOARD_FETCH = 'scoreboard/FETCH';
export const SCOREBOARD_FETCH_SUCCESS = 'scoreboard/FETCH_SUCCESS';
export const SCOREBOARD_UPDATE = 'scoreboard/UPDATE';
export const SCOREBOARD_NAVIGATION_EVENTS_FETCH = 'scoreboard/NAVIGATION_EVENTS_FETCH';
export const SCOREBOARD_NAVIGATION_EVENTS_FETCH_SUCCESS = 'scoreboard/NAVIGATION_EVENTS_FETCH_SUCCESS';
export const SCOREBOARD_STATS_UPDATE = 'scoreboard/STATS_UPDATE';
export const SCOREBOARD_COMMENT_ADD = 'scoreboard/COMMENT_ADD';
export const SCOREBOARD_COMMENT_UPDATE = 'scoreboard/COMMENT_UPDATE';
export const SCOREBOARD_TIMELINE_UPDATE = 'scoreboard/TIMELINE_UPDATE';
export const SCOREBOARD_CLOCK_UPDATE = 'scoreboard/CLOCK_UPDATE';
export const SCOREBOARD_SPORT_RADAR_WIDGET_ERROR = 'scoreboard/SPORT_RADAR_WIDGET_ERROR';

const commentCompare = (a, b) => {
  if (a.datetime !== b.datetime) {
    return b.datetime - a.datetime;
  }
  return b.id - a.id;
};

export const scoreboardInit = (opts) => ({
  type: SCOREBOARD_INIT,
  ...opts,
});

export const scoreboardRemove = (opts) => ({
  type: SCOREBOARD_REMOVE,
  ...opts,
});

export const scoreboardFetchSuccess = (scoreboard) => ({
  type: SCOREBOARD_FETCH_SUCCESS,
  scoreboard,
});

export const scoreboardNavigationEventsFetchSuccess = (navigationEvents) => ({
  type: SCOREBOARD_NAVIGATION_EVENTS_FETCH_SUCCESS,
  navigationEvents,
});

export const scoreboardNavigationEventsFetch = (cacheBust = '') => (dispatch, getState) => {
  const state = getState();
  if (!state.scoreboard || !state.scoreboard.sportPathId || !state.scoreboard.event) {
    return { type: SCOREBOARD_NAVIGATION_EVENTS_FETCH, promise: Promise.resolve() };
  }

  const fetch = fetchNavigationEvents(
    state.scoreboard.sportPathId,
    state.scoreboard.event.event_path_tree.id,
    cacheBust,
  ).then((resp) => {
    dispatch(scoreboardNavigationEventsFetchSuccess(resp.events));
  });

  return {
    type: SCOREBOARD_NAVIGATION_EVENTS_FETCH,
    promise: fetch,
  };
};

export const scoreboardUpdate = (delta) => ({
  type: SCOREBOARD_UPDATE,
  delta,
});

export const commentAdd = (comment) => ({
  type: SCOREBOARD_COMMENT_ADD,
  comment: {
    ...comment,
    messageType: String(comment.messageType).toLowerCase(),
  },
});

export const commentUpdate = (id, delta) => ({
  type: SCOREBOARD_COMMENT_UPDATE,
  id,
  delta,
});

export const statsUpdate = (delta) => ({
  type: SCOREBOARD_STATS_UPDATE,
  delta,
});

export const timelineUpdate = (delta) => ({
  type: SCOREBOARD_TIMELINE_UPDATE,
  delta,
});

export const scoreboardFetch = () => (dispatch, getState) => {
  const state = getState();
  if (!state.scoreboard || !state.scoreboard.locale || !state.scoreboard.eventId) {
    return null;
  }
  return fetchData(state.scoreboard)
    .then((data) => {
      data?.comments?.sort(commentCompare);
      dispatch(scoreboardFetchSuccess(data));
    });
};

export const clockUpdate = (clockValue) => ({
  type: SCOREBOARD_CLOCK_UPDATE,
  clockValue,
});

export const sportRadarWidgetError = () => ({ type: SCOREBOARD_SPORT_RADAR_WIDGET_ERROR });

const initialState = {
  eventId: null,
  locale: null,
  event: null,
  navigationEvents: [],
  data: null,
};

export default (state = initialState, action = {}) => {
  let comments;

  switch (action.type) {
    case SCOREBOARD_INIT:
      return {
        ...state,
        eventId: action.eventId,
        locale: action.locale,
        event: action.event,
        dict: action.dict,
        brandId: parseInt(action.brandId, 10) || 1,
        sportPathId: parseInt(action.sportPathId, 10) || 0,
        navigationEvents: action.navigationEvents || [],
        data: {},
      };
    case SCOREBOARD_REMOVE:
      return {
        ...state,
        ...initialState,
      };
    case SCOREBOARD_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.scoreboard,
        },
      };
    case SCOREBOARD_NAVIGATION_EVENTS_FETCH:
      return {
        ...state,
        navigationEventsFetching: true,
      };
    case SCOREBOARD_NAVIGATION_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        navigationEvents: action.navigationEvents,
        navigationEventsFetching: false,
      };
    case SCOREBOARD_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          essential_scoreboard: {
            ...state.data.essential_scoreboard,
            disabledScoreboard: false,
            ...action.delta,
          },
        },
      };
    case SCOREBOARD_COMMENT_ADD:
      if (playerInfoRegexp.test(action.comment.messageType)) {
        comments = state.data.comments.slice(0);
        updateCommentsWithPlayerComment(comments, action.comment);
      } else {
        comments = [
          ...state.data.comments,
          ...[action.comment],
        ];
      }
      return {
        ...state,
        data: {
          ...state.data,
          comments: commentsFilter(comments).sort(commentCompare),
        },
      };
    case SCOREBOARD_COMMENT_UPDATE:
      if (!state.data || !state.data.comments) {
        return state;
      }
      comments = state.data.comments.map((comment) => (
        (comment.id === action.id) ? { ...comment, ...action.delta } : comment
      ));
      return {
        ...state,
        data: {
          ...state.data,
          comments,
        },
      };
    case SCOREBOARD_STATS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            ...action.delta,
          },
        },
      };
    case SCOREBOARD_TIMELINE_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          timeline: action.delta,
        },
      };
    case SCOREBOARD_CLOCK_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          localClock: action.clockValue,
        },
      };
    case SCOREBOARD_SPORT_RADAR_WIDGET_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          sportRadarWidgetError: true,
        },
      };
    default:
      return state;
  }
};

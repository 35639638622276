import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import { setFragment } from 'bv-helpers/location';
import Timer from './timer';

const EventSliderItem = ({
  event, selectedEventId, sportId, sportEventPathId,
}) => {
  const buttonClass = classnames(
    'scrolled-item__sport-button',
    { disabled: selectedEventId !== event.id },
  );

  return (
    <li className="scrolled-item">
      <div className="button-container">
        <button
          type="button"
          onClick={() => {
            setFragment(`/sports/${sportEventPathId}/meetings/${event.meetingId}/events/${event.id}`);
          }}
          className={buttonClass}
        >
          <Icon id={`sport-${sportId}`} type="virtual-scroller-item-icon" />
          <span className="team-name">{event.opponentAShortDescription || event.opponentADescription}</span>
          <span className="team-name">{event.opponentBShortDescription || event.opponentBDescription}</span>
          <Timer startTime={event.startTime} />
        </button>
      </div>
    </li>
  );
};

EventSliderItem.propTypes = {
  selectedEventId: PropTypes.isRequired,
  event: PropTypes.isRequired,
  sportId: PropTypes.isRequired,
  sportEventPathId: PropTypes.isRequired,
};

export default EventSliderItem;

import withSuspense from 'bv-with-suspense';

const InPlayDefaultScoreboard = withSuspense(() => import('../components/in_play/default_scoreboard'));
const InPlayBasketballScoreboard = withSuspense(() => import('../components/in_play/basketball_scoreboard'));
const InPlayTennisScoreboard = withSuspense(() => import('../components/in_play/tennis_scoreboard'));
const InPlayFootballScoreboard = withSuspense(() => import('../components/in_play/football_scoreboard'));
const InPlayOutrightScoreboard = withSuspense(() => import('../components/in_play/outright_scoreboard'));
const InPlayScoreTableScoreboard = withSuspense(() => import('../components/in_play/score_table_scoreboard'));

const PreEventDefaultScoreboard = withSuspense(() => import('../components/pre_event/default_scoreboard'));
const PreEventFootballScoreboard = withSuspense(() => import('../components/pre_event/football_scoreboard'));
const PreEventBasketballScoreboard = withSuspense(() => import('../components/pre_event/basketball_scoreboard'));
const PreEventOutrightScoreboard = withSuspense(() => import('../components/pre_event/outright_scoreboard'));
const PreEventTennisScoreboard = withSuspense(() => import('../components/pre_event/tennis_scoreboard'));
const PreEventDartsScoreboard = withSuspense(() => import('../components/pre_event/darts_scoreboard'));
const PreEventVirtualEsportScoreboard = withSuspense(() => import('../components/pre_event/virtual_esport_countdown_scoreboard'));

const GolfScoreboard = withSuspense(() => import('../components/golf_scoreboard'));
const DisabledScoreboard = withSuspense(() => import('../components/disabled_scoreboard'));

const ScoreboardFactory = (props) => {
  let component = null;
  if (props.scoreboard && props.scoreboard.essential_scoreboard
      && props.scoreboard.essential_scoreboard.sId) {
    if (props.scoreboard.essential_scoreboard.inPlay) {
      if (props.event.event_type === 'RANK') {
        // Golf has scoreboards even for rank events
        if (props.scoreboard.essential_scoreboard.sId === 400) {
          component = <GolfScoreboard />;
          return component;
        }
        component = <InPlayOutrightScoreboard />;
        return component;
      }
      if (props.event.is_virtual_esport) {
        component = <PreEventVirtualEsportScoreboard />;
        return component;
      }

      switch (props.scoreboard.essential_scoreboard.sId) {
        case 100:
          component = <InPlayFootballScoreboard />;
          break;
        case 601600:
          component = <InPlayBasketballScoreboard />;
          break;
        case 600:
          component = <InPlayTennisScoreboard />;
          break;
        case 4000: // Badminton
        case 433100: // Baseball
        case 1250: // BeachVolleyball
        case 3400: // Bowls
        case 17500: // Cricket
        case 30000: // Curling
        case 2100: // IceHockey
        case 12000: // Netball
        case 364700: // Snooker
        case 96610: // Speedway
        case 950: // Squash
        case 650: // TableTennis
        case 1200: // Volleyball
        case 1600: // Futsal
        case 5000: // Autralian Rules Football
        case 965300:
          component = <InPlayScoreTableScoreboard />;
          break;
        case 30100: // Biathlon
        case 30200: // Nordic Combined
        case 967300: // Cycling
        case 967700: // Winter Sports
        case 364800: // Boxing UFC
        case 965700: // Entertainment
        case 300: // Motor Racing
        case 966900: // Motor Cycling
        case 968800: // Olympic Games
        case 105700: // Other Sports
        case 31000: // Archery
        case 964700: // Athletics
        case 31002: // Canoe Slalom
        case 31001: // Canoe Sprint
        case 31010: // Cycling - BMX
        case 31011: // Cycling - Mountain Bikes
        case 31012: // Cycling - Track
        case 31020: // Diving
        case 31032: // Equestrian - Dressage
        case 31030: // Equestrian - Eventing
        case 31031: // Equestrian - Show Jumping
        case 31040: // Fencing
        case 31060: // Judo
        case 31070: // Modern Pentathlon
        case 31080: // Rowing
        case 31090: // Sailing
        case 31100: // Shooting
        case 31120: // Synchronised Swimming
        case 31110: // Swimming
        case 31130: // Taekwondo
        case 31140: // Triathlon
        case 31170: // Wrestling
        case 31160: // Weightlifting
        case 965500: // Poker
        case 965600: // Politics
        case 1021000: // Cross Country Skiing
        case 31500: // Gymnastics
        case 1300: // F1
        case 982100: // Football specials
        case 1327866: // MMA
          component = null;
          break;
        case 400: // Golf
          component = <GolfScoreboard />;
          break;
        default:
          component = <InPlayDefaultScoreboard />;
          break;
      }
    } else {
      if (props.event.event_type === 'RANK') {
        // Golf has scoreboards even for rank events
        if (props.scoreboard.essential_scoreboard.sId === 400) {
          component = <GolfScoreboard />;
          return component;
        }
        component = <PreEventOutrightScoreboard />;
        return component;
      }
      if (props.event.is_virtual_esport) {
        component = <PreEventVirtualEsportScoreboard />;
        return component;
      }
      switch (props.scoreboard.essential_scoreboard.sId) {
        case 100:
          component = <PreEventFootballScoreboard />;
          break;
        case 601600:
          component = <PreEventBasketballScoreboard />;
          break;
        case 600:
          component = <PreEventTennisScoreboard />;
          break;
        case 965300:
          component = <PreEventDartsScoreboard />;
          break;
        case 30100: // Biathlon
        case 30200: // Nordic Combined
        case 967300: // Cycling
        case 967700: // Winter Sports
        case 364800: // Boxing UFC
        case 965700: // Entertainment
        case 300: // Motor Racing
        case 966900: // Motor Cycling
        case 968800: // Olympic Games
        case 105700: // Other Sports
        case 31000: // Archery
        case 964700: // Athletics
        case 31002: // Canoe Slalom
        case 31001: // Canoe Sprint
        case 31010: // Cycling - BMX
        case 31011: // Cycling - Mountain Bikes
        case 31012: // Cycling - Track
        case 31020: // Diving
        case 31032: // Equestrian - Dressage
        case 31030: // Equestrian - Eventing
        case 31031: // Equestrian - Show Jumping
        case 31040: // Fencing
        case 31060: // Judo
        case 31070: // Modern Pentathlon
        case 31080: // Rowing
        case 31090: // Sailing
        case 31100: // Shooting
        case 31120: // Synchronised Swimming
        case 31110: // Swimming
        case 31130: // Taekwondo
        case 31140: // Triathlon
        case 31170: // Wrestling
        case 31160: // Weightlifting
        case 965500: // Poker
        case 965600: // Politics
        case 1021000: // Cross Country Skiing
        case 31500: // Gymnastics
        case 1300: // F1
        case 982100: // Football specials
        case 1327866: // MMA
          component = <PreEventOutrightScoreboard />;
          break;
        case 400: // Golf
          component = <GolfScoreboard />;
          break;
        default:
          component = <PreEventDefaultScoreboard />;
          break;
      }
    }
  }
  if (props.scoreboard && props.scoreboard.essential_scoreboard
      && (props.scoreboard.essential_scoreboard.disabledScoreboard
      || props.scoreboard.essential_scoreboard.scoreboardUrlFragment)) {
    component = <DisabledScoreboard />;
  }

  return component;
};

export default ScoreboardFactory;

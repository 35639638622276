import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { scoreboardFetch } from '../duck';
import ScoreboardFactory from '../services/scoreboard_factory';
import EventSlider from './event_slider/event_slider';

const App = ({
  event,
  scoreboard,
  eventId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(scoreboardFetch());
  }, [eventId]);

  return (
    <div className="scoreboard-container">
      { event?.is_virtual_esport && <EventSlider /> }
      <ul className="scoreboard list arrow-toright with-score">
        {ScoreboardFactory({ event, scoreboard })}
      </ul>
    </div>
  );
};

App.propTypes = {
  event: PropTypes.isRequired,
  eventId: PropTypes.isRequired,
  scoreboard: PropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.scoreboard.event,
  eventId: state.scoreboard.eventId,
  scoreboard: state.scoreboard.data,
});

export default connect(mapStateToProps)(App);

import { registerHandler, unregisterHandler } from 'event-bus';

import {
  scoreboardHandler, statsHandler, commentsHandler, timelineHandler,
} from './services/message_handlers';
import { SCOREBOARD_FETCH_SUCCESS, SCOREBOARD_REMOVE } from './duck';

const register = (addresses) => {
  registerHandler(addresses.essentialScoreboardCallback, scoreboardHandler);
  registerHandler(addresses.comment, commentsHandler);
  registerHandler(addresses.stats, statsHandler);
  registerHandler(addresses.timeline, timelineHandler);
};

const unregister = (addresses) => {
  unregisterHandler(addresses.essentialScoreboardCallback, scoreboardHandler);
  unregisterHandler(addresses.comment, commentsHandler);
  unregisterHandler(addresses.stats, statsHandler);
  unregisterHandler(addresses.timeline, timelineHandler);
};

export default () => (next) => (action) => {
  switch (action.type) {
    case SCOREBOARD_FETCH_SUCCESS:
      if (action.scoreboard && action.scoreboard.essential_scoreboard
          && action.scoreboard.essential_scoreboard.addresses) {
        register(action.scoreboard.essential_scoreboard.addresses);
      }
      break;

    case SCOREBOARD_REMOVE:
      if (action.oldScoreboard && action.oldScoreboard.addresses) {
        unregister(action.oldScoreboard.addresses);
      }
      break;

    default:
      break;
  }
  next(action);
};

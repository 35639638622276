import { locale } from 'bv-i18n';

export const getScoreboard = (state) => (
  state?.scoreboard?.data?.essential_scoreboard
);

export const getBrandId = (state) => (
  state.scoreboard.brandId
);

export const getSportPathId = (state) => (
  state.scoreboard.sportPathId
);

export const getStats = (state) => (
  state.scoreboard.data?.stats || {}
);

export const getComments = (state) => (
  state.scoreboard.data?.comments || []
);

export const getStreaming = (state) => state.streaming;

export const getLastComment = (state) => (
  (state.scoreboard.data.comments && state.scoreboard.data.comments[0]) || {}
);

export const getCommentWithTimer = (state) => (
  (state.scoreboard.data.comments
  && state.scoreboard.data.comments.find((comm) => comm.timer !== null && comm.timer !== undefined)
  ) || {}
);

export const getEvent = (state) => (
  state.scoreboard.event
);

export const getNavigationEvents = (state) => (
  state.scoreboard.navigationEvents
);

export const getDict = (state) => (
  state.scoreboard.dict
);

export const getInRunningMessageTranslations = (state) => (
  state.scoreboard.dict.in_running_messages || {}
);

export const getTimeline = (state) => (
  state.scoreboard.data?.timeline || []
);

export const getLocalClock = (state) => (
  state.scoreboard.data?.localClock || ''
);

export const getPenalties = (state) => (
  state.scoreboard.data.essential_scoreboard.penaltyShootout || {}
);

export const hasSportRadarScoreboard = (state) => {
  const { providerEventId, provider } = (
    state.scoreboard.data.essential_scoreboard.externalScoreboardConfiguration || {}
  );
  if (state.scoreboard.data.sportRadarWidgetError) return false;
  return providerEventId && provider === 'BET_RADAR';
};

// TODO: This should be using reselect
export const getStartTime = (state) => {
  if (state.scoreboard.data
      && state.scoreboard.data.essential_scoreboard
      && state.scoreboard.data.essential_scoreboard.sTs
  ) {
    return new Date(state.scoreboard.data.essential_scoreboard.sTs)
      .toLocaleString(locale(), {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      });
  }
  return '';
};

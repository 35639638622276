import 'public-path';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getJSON } from 'bv-fetch';
import { addMiddleware } from 'redux-dynamic-middlewares';
import { v as bvVar } from 'bv';
import { locale } from 'bv-i18n';

import reducer, { scoreboardInit, scoreboardRemove } from './duck';
import scoreboardMiddleware from './middleware';
import App from './components/app.jsx';
import { getScoreboard } from './selectors';

const { store, addReducers } = window.reduxState;

addReducers({ scoreboard: reducer });
addMiddleware(scoreboardMiddleware);

const ScoreboardApp = ({
  event,
  sportPathId,
  meetingId,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getJSON(`/bv_api/dictionary/${locale()}/${event.sport_id}`),
      getJSON(`/api/sports/${sportPathId}/meetings/${meetingId}${event.is_virtual_esport ? '?limit=12' : ''}`),
    ]).then((responses) => {
      store.dispatch(scoreboardInit({
        eventId: event.id,
        event,
        locale: locale(),
        brandId: bvVar('brandId'),
        dict: responses[0] || {},
        sportPathId,
        navigationEvents: responses[1].events || [],
      }));
      setLoading(false);
    });
    return () => {
      store.dispatch(
        scoreboardRemove({ oldScoreboard: getScoreboard(store.getState()) }),
      );
    };
  }, []);

  if (loading) {
    return null;
  }

  return <App />;
};

ScoreboardApp.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  sportPathId: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
};

export default ScoreboardApp;

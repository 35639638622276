import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'bv-components';
import { createStructuredSelector } from 'reselect';
import { setFragment } from 'bv-helpers/location';
import {
  getEvent, getScoreboard, getSportPathId, getNavigationEvents,
} from '../../selectors';
import { scoreboardNavigationEventsFetch } from '../../duck';
import EventSliderItem from './event_slider_item';

const EventCarousel = ({
  events,
  scoreboard,
  sportEventPathId,
  fetchNavigationEvents,
  selectedEvent,
}) => {
  useEffect(() => {
    if (scoreboard && scoreboard.periodKey === 'EOE') {
      fetchNavigationEvents(selectedEvent.id).promise.then(() => {
        const currentEventIndex = events.findIndex((e) => selectedEvent.id === e.id);
        const nextEvent = events[currentEventIndex + 1];
        if (nextEvent) {
          setFragment(
            `/sports/${sportEventPathId}/meetings/${nextEvent.meetingId}/events/${nextEvent.id}?prev=${selectedEvent.id}`,
          );
        }
      });
    }
  }, [((scoreboard && scoreboard.periodKey) || null)]);

  const ref = useRef();
  const location = useLocation();
  const previousEventId = Number(new URLSearchParams(location.search).get('prev') || 0);

  if (events.length < 2) return null;

  return (
    <Carousel scrollStep={88} ref={ref} className="coupon-scroller coupon-scroller-scoreboard" embedded>
      {events.map((event) => (
        event.id !== previousEventId
          ? (
            <EventSliderItem
              event={event}
              sportEventPathId={sportEventPathId}
              sportId={selectedEvent.sport_id}
              selectedEventId={selectedEvent.id}
            />
          ) : null))}
    </Carousel>
  );
};

EventCarousel.propTypes = {
  sportEventPathId: PropTypes.isRequired,
  events: PropTypes.isRequired,
  scoreboard: PropTypes.isRequired,
  selectedEvent: PropTypes.isRequired,
  fetchNavigationEvents: PropTypes.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sportEventPathId: getSportPathId,
  scoreboard: getScoreboard,
  events: getNavigationEvents,
  selectedEvent: getEvent,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNavigationEvents(cacheBust) {
    return dispatch(scoreboardNavigationEventsFetch(cacheBust));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCarousel);

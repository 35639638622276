import {
  scoreboardUpdate, statsUpdate, commentAdd, timelineUpdate,
} from '../duck';

const { store } = window.reduxState;

export const scoreboardHandler = (err, { body: data }) => {
  store.dispatch(scoreboardUpdate(data));
};

export const statsHandler = (err, { body: data }) => {
  store.dispatch(statsUpdate(data));
};

export const commentsHandler = (err, { body: data }) => {
  store.dispatch(commentAdd(data));

  const messageType = data.messageType.toLowerCase();
  if (messageType === 'fault' || messageType === 'point') {
    store.dispatch(commentAdd({
      messageType: messageType === 'point' ? 'FIRST_SERVICE' : 'SECOND_SERVICE',
      datetime: data.datetime + 1,
      id: data.id + 1,
      isOddScore: data.isOddScore,
      clk: data.clk,
      opp: data.activeOpponent,
    }));
  }
};

export const timelineHandler = (err, { body: data }) => {
  store.dispatch(timelineUpdate(data));
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { humanTimeDistance } from 'bv-helpers/datetime';

const formatNumber = (number) => (`0${number}`).substr(-2);

const Timer = ({ startTime }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const tick = () => {
      const { minutes, seconds, distance } = humanTimeDistance(
        startTime, { hoursAsMintues: true },
      );

      if (distance <= 0) {
        setTime(t('javascript.live_now'));
      } else if (minutes >= 1) {
        setTime(t('javascript.in_x_minutes', { minutes }));
      } else {
        setTime(
          t('javascript.in_x_seconds', {
            seconds: `${formatNumber(minutes)}:${formatNumber(seconds)}`,
          }),
        );
      }
    };

    const interval = setInterval(tick, 1000);
    tick();

    return (() => clearInterval(interval));
  }, [startTime]);

  return <span className="timer">{time}</span>;
};

Timer.propTypes = {
  startTime: PropTypes.isRequired,
};

export default Timer;
